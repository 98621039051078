import getLocale from '@invitato/helpers/dist/getLocale';

export const txtForm = {
  title: getLocale('Konfirmasi Kehadiran', 'rsvp'),
  desc: getLocale(
    `Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga. Mohon konfirmasi kehadiran Anda melalui formulir reservasi di bawah ini:`,
    `Your presence shall be a great honor for us and our families. Please confirm your attendance through the reservation form below:`,
  ),
  name: getLocale('Nama', 'Name'),
  nameHelper: getLocale(
    'RSVP bersifat personal sehingga hanya bisa mengkonfirmasikan 1 nama tamu undangan saja',
    'Guest can only confirm 1 name due to personalized RSVP',
  ),
  hp: getLocale('Nomor HP', 'Phone Number'),
  address: getLocale('Alamat', 'Address'),
  willYoutAttend: getLocale('Apakah Anda akan hadir?', 'Will you attend the wedding?'),
  willAttend: getLocale('Ya, saya akan hadir', 'Yes, I  will gladly attend'),
  willAttendOnline: getLocale(
    'Ya, hadir secara virtual',
    'No, I will not attend but may watch the online streaming instead',
  ),
  noAttend: getLocale('Tidak, saya tidak bisa hadir', `No, I can't attend the wedding`),
  willYouBringPartner: getLocale(
    'Berapa jumlah tamu yang akan hadir?',
    `How many guests will be attend?`,
  ),
  withPartner: getLocale(
    'Ya, saya akan membawa 1 partner',
    `Yes, I will bring my partner (only one +1 is allowed per pax)`,
  ),
  noPartner: getLocale('Tidak, saya akan hadir sendiri', `No, I will be by myself`),
  submit: getLocale('Kirim Konfirmasi', `Submit Confirmation`),
  required: getLocale('Wajib diisi!', 'This field is required!'),
  success: getLocale('Sukses!', 'Success!'),
  msgSuccess: getLocale(
    'Formulir reservasi Anda berhasil dikirim. Terima Kasih!',
    'You are registered. Thank you!',
  ),
  msgError: getLocale(
    'Ada kesalahan ketika mengirimkan reservasi Anda. Silahkan coba beberapa saat lagi.',
    'Something wrong happened. Please try again later or refresh your browser!',
  ),
  singleGuestInfo: getLocale(
    `*) Berdasarkan kondisi saat ini, satu tamu hanya boleh membawa 1 orang partner atau pasangan dalam satu undangan. Terima kasih atas pengertiannya.`,
    `*) Adjusting to current situation, we are only able to accommodate single guest on this invitation. Thank you for your cooperation.`,
  ),
  back: getLocale('Kembali', 'Back'),
  invalidEmail: getLocale('Email tidak valid', 'Invalid email address'),
  invalidPhone: getLocale('Nomor HP tidak valid', 'Invalid phone number'),
};
