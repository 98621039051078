import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  Heading,
  useDisclosure,
  Flex,
  MenuButton,
  Menu,
  InputGroup,
  Icon,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import GuestInformation from './GuestInformation';
import { BiChevronDown } from 'react-icons/bi';

import { useGuest } from '@/context/guest';
import useFormFields from '@hooks/useFormFields';
import useRsvp from '@/usecase/use-rsvp';
import useAddNewGuest from '@/usecase/use-add-new-guest';
import useFamily from '@/hooks/useFamily';
import useGroupLink from '@/hooks/useGroupLink';

import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';
import { ASSETS_BG_PATTERN } from '@/constants/assets';
import { RSVP_STATUS } from '@/constants/api';
import { COUNTRY_CODES } from '@/constants/country-codes';
import {
  FORM_LABEL_PROPS,
  INPUT_COMMON_PROPS,
  PHONE_SELECT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  TYPE,
  ERROR_TYPE,
  ERROR_PROPS,
} from './types';
import {
  ENABLE_GUEST_INFORMATION,
  RSVP_ENABLE_NAME,
  RSVP_ENABLE_PHONE_NUMBER,
  RSVP_ENABLE_ADDRESS,
  RSVP_ENABLE_EMAIL,
  ENABLE_QR_INVITATION,
  ENABLE_PARTNER_MODE,
} from '@/constants/feature-flags';

import { txtForm as txt } from './locales';

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const isFamily = useFamily();
  const isGroupLink = useGroupLink();
  const { guest, onUpdateGuest, isRegistered } = useGuest();
  const { onAddNewGuest } = useAddNewGuest();
  const { onUpdateRsvp } = useRsvp();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const { formFields, createChangeHandler } = useFormFields({
    name: isRegistered ? guest.name : '',
    phone_number: guest.phone_number || '',
    guest_quota: guest.guest_quota,
    total_guest: guest.guest_confirm || guest.guest_quota || 1,
    address: guest.address === '-' ? '' : guest.address || '',
    email: guest.email === '-' ? '' : guest.email || '',
    is_attended: guest.status === 'NOT_ATTEND' ? TYPE.NO : TYPE.YES,
  });

  const totalFinalQuota = ENABLE_PARTNER_MODE ? guest.guest_quota : 2;
  const [selectedCountryCode, setSelectedCountryCode] = useState(guest.country_code || '62');

  const handleCountryCodeChange = (code) => {
    setSelectedCountryCode(code);
  };

  const callToasterError = (desc, title = 'Oops!') => {
    toast({
      title,
      description: desc,
      status: 'error',
      isClosable: true,
    });
  };

  const callToasterSuccess = (desc, title = txt.success[lang]) => {
    toast({
      title,
      description: desc,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  /**
   * function to submit to BE with check the form value first
   */

  const onSubmitForm = async () => {
    const { name, address, phone_number, total_guest, is_attended, email } = formFields;
    // reset error state

    setErrorType(ERROR_TYPE);
    // define current error state
    let currentErrorType = ERROR_TYPE;

    // Make sure user already input the value
    /**
     * ERROR CHECKING
     */
    if (RSVP_ENABLE_NAME && !name) {
      currentErrorType = { ...currentErrorType, name: txt.required[lang] };
    }

    if (RSVP_ENABLE_PHONE_NUMBER) {
      if (!phone_number) {
        currentErrorType = { ...currentErrorType, phone: txt.required[lang] };
      } else if (phone_number.length > 13 || phone_number.length < 5) {
        currentErrorType = { ...currentErrorType, phone: txt.invalidPhone[lang] };
      }
    }

    if (RSVP_ENABLE_ADDRESS && !address) {
      currentErrorType = { ...currentErrorType, address: txt.required[lang] };
    }

    if (RSVP_ENABLE_EMAIL) {
      if (!email) {
        currentErrorType = { ...currentErrorType, email: txt.required[lang] };
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        currentErrorType = { ...currentErrorType, email: txt.invalidEmail[lang] };
      }
    }

    if (
      currentErrorType.address ||
      currentErrorType.email ||
      currentErrorType.name ||
      currentErrorType.phone
    ) {
      setErrorType(currentErrorType);
      return;
    }
    setIsLoading(true);
    const guestPayload = {
      name,
      address: address || '-',
      countryCode: selectedCountryCode,
      phoneNumber: phone_number || '-',
      email: email || '-',
      shift: guest.shift || '-',
      system_info: 'WARNING_Terdapat Perubahan dari User_',
    };

    // Usecase: Group or General Invitation
    // We will `insert` instead of `update` if user doens't have code yet. But,
    // we will throw error if QR Invitation
    if (!guest.code) {
      if (ENABLE_QR_INVITATION) {
        setIsLoading(false);
        callToasterError(txt.msgError[lang]);
        return;
      }
      await onAddNewGuest({
        ...guestPayload,
        desc: isGroupLink ? 'Undangan Fisik' : guest.name || '-',
        guest_quota: guest.guest_quota,
        guest_confirm: total_guest && is_attended === TYPE.YES ? total_guest : 0,

        status: is_attended === TYPE.YES ? RSVP_STATUS.attend : RSVP_STATUS.notAttend,
        onSuccess: (guestCode) => {
          setIsLoading(false);
          callToasterSuccess(txt.msgSuccess[lang]);
          onUpdateGuest(guestCode, name);
        },
        onFailed: () => {
          setIsLoading(false);
          callToasterError(txt.msgError[lang]);
        },
      });
      return;
    }
    await onUpdateRsvp({
      ...guestPayload,
      desc: guest.desc || '-',
      isAttended: is_attended === TYPE.YES,
      totalGuest: total_guest && is_attended === TYPE.YES ? total_guest : 0,

      onSuccess: () => {
        setIsLoading(false);
        // onResetFormWithKey('phone_number');
        callToasterSuccess(txt.msgSuccess[lang]);
      },
      onFailed: () => {
        setIsLoading(false);
        callToasterError(txt.msgError[lang]);
      },
    });
  };

  return (
    <Box
      bgColor="bgPrimary"
      bgImage={`url(${ASSETS_BG_PATTERN})`}
      bgSize="cover"
      bgPosition="center"
      position="relative"
      {...rest}
    >
      <Box height="100%" padding="0 32px 32px">
        <Box marginTop="42px" width="100%" zIndex={2} position="relative">
          <Box borderRadius="16px" position="relative">
            <Box width="100%">
              {/* Title & Desc Section */}
              <Box textAlign="center">
                <WithAnimation>
                  <Heading
                    fontWeight="normal"
                    color="secondaryColorText"
                    size="xl"
                    fontFamily="CustomFont"
                  >
                    {txt.title[lang]}
                  </Heading>
                </WithAnimation>
                <WithAnimation>
                  <Text
                    marginTop="16px"
                    color="mainColorText"
                    fontSize="md"
                    dangerouslySetInnerHTML={{ __html: txt.desc[lang] }}
                  />
                </WithAnimation>
              </Box>

              {/* Form Sections - Name */}
              {RSVP_ENABLE_NAME && (
                <WithAnimation>
                  <FormControl margin="8px 0" isInvalid={errorType.name}>
                    <FormLabel {...FORM_LABEL_PROPS} marginBottom={0}>
                      {txt.name[lang]}:
                    </FormLabel>
                    <FormLabel fontSize="14px">*) {txt.nameHelper[lang]}</FormLabel>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      placeholder="..."
                      value={formFields.name}
                      onChange={createChangeHandler('name')}
                      disabled={isRegistered}
                    />
                    <FormErrorMessage {...ERROR_PROPS}>{`* ${errorType.name}`}</FormErrorMessage>
                  </FormControl>
                </WithAnimation>
              )}
              {/* Form Sections - Phone Number */}
              {RSVP_ENABLE_PHONE_NUMBER && (
                <FormControl margin="16px 0" isInvalid={errorType.phone}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.hp[lang]}:</FormLabel>
                  <InputGroup size="sm">
                    <Menu>
                      <MenuButton
                        {...PHONE_SELECT_COMMON_PROPS}
                        as={Button}
                        rightIcon={<Icon as={BiChevronDown} />}
                      >
                        {`+${selectedCountryCode}`}
                      </MenuButton>
                      <MenuList maxHeight="200px" overflowY="scroll" zIndex={3}>
                        {COUNTRY_CODES.map((country) => (
                          <MenuItem
                            key={country.dial_code}
                            onClick={() => handleCountryCodeChange(country.dial_code)}
                          >
                            {`${country.name}: +${country.dial_code}`}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      placeholder="..."
                      type="number"
                      value={formFields.phone_number}
                      onChange={createChangeHandler('phone_number')}
                    />
                  </InputGroup>
                  <FormErrorMessage marginTop="4px">{errorType.phone}</FormErrorMessage>
                </FormControl>
              )}
              {/* Form Sections - Address */}
              {RSVP_ENABLE_ADDRESS && (
                <WithAnimation>
                  <FormControl margin="8px 0" isInvalid={errorType.address}>
                    <FormLabel {...FORM_LABEL_PROPS}>{txt.address[lang]}:</FormLabel>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      height="40px"
                      placeholder="..."
                      value={formFields.address}
                      onChange={createChangeHandler('address')}
                    />
                    <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.address}</FormErrorMessage>
                  </FormControl>
                </WithAnimation>
              )}
              {/* Form Sections - Email */}
              {RSVP_ENABLE_EMAIL && (
                <FormControl margin="8px 0" isInvalid={errorType.email}>
                  <FormLabel {...FORM_LABEL_PROPS}>Email:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.email}
                    onChange={createChangeHandler('email')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.email}</FormErrorMessage>
                </FormControl>
              )}
              {/* Form Sections - Attendance */}
              <WithAnimation>
                <FormControl margin="8px 0">
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.willYoutAttend[lang]}</FormLabel>
                  <Select
                    {...SELECT_COMMON_PROPS}
                    value={formFields.is_attended}
                    onChange={createChangeHandler('is_attended')}
                  >
                    <option value={TYPE.YES} color="white">
                      {txt.willAttend[lang]}
                    </option>
                    <option value={TYPE.NO}>{txt.noAttend[lang]}</option>
                  </Select>
                </FormControl>
              </WithAnimation>
              {/* Form Sections - Partner */}
              {formFields.is_attended === TYPE.YES && (
                <WithAnimation>
                  <FormControl>
                    <FormLabel {...FORM_LABEL_PROPS}>{txt.willYouBringPartner[lang]}</FormLabel>
                    <Select
                      {...SELECT_COMMON_PROPS}
                      value={formFields.total_guest}
                      onChange={createChangeHandler('total_guest')}
                    >
                      {[...Array(totalFinalQuota).keys()].map((item, index) => {
                        return (
                          <option key={item} value={index + 1} style={{ color: 'black' }}>
                            {index + 1}
                          </option>
                        );
                      })}
                    </Select>
                    {/* SET to TRUE if you want to add some information if user bring-partner YES */}
                    {formFields.total_guest > TYPE.YES && false && (
                      <FormHelperText color="mainColorText" fontSize="sm" fontStyle="italic">
                        {txt.singleGuestInfo[lang]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </WithAnimation>
              )}

              <WithAnimation>
                <Flex justifyContent="end">
                  {ENABLE_GUEST_INFORMATION && (
                    <Button
                      bgColor="bgPrimary"
                      marginTop="24px"
                      size="sm"
                      type="button"
                      fontFamily="body"
                      fontWeight="normal"
                      fontSize="xs"
                      color="mainColorText"
                      onClick={onOpen}
                      borderRadius="8px"
                      marginRight="8px"
                      {...BUTTON_PROPS}
                    >
                      {isFamily ? 'FAMILY INFORMATION' : 'GUEST INFORMATION'}
                    </Button>
                  )}
                  <Button
                    isDisabled={true}
                    isLoading={isLoading}
                    bgColor="bgAlternative"
                    size="sm"
                    type="button"
                    fontFamily="body"
                    fontWeight="normal"
                    boxShadow="md"
                    color="mainColorText"
                    padding="4px 16px"
                    borderRadius="16px"
                    display="block"
                    margin="24px auto 0 auto"
                    onClick={onSubmitForm}
                    {...BUTTON_PADDING_PROPS}
                  >
                    {txt.submit[lang]}
                  </Button>
                </Flex>
              </WithAnimation>
            </Box>
          </Box>
        </Box>
      </Box>
      <GuestInformation visible={isOpen} onClose={onClose} lang={lang} />
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
